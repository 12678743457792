.wizard-container {
  
  max-width:100%;
  margin: 15px auto;
  background-color: #fff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 20px;
}

.wizard-steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.step {
  flex: 1;
  text-align: center;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.step.active {
  background: linear-gradient(45deg, var(--primary-color), rgba(28, 4, 92, 0.822));
  color: #fff;
}

.wizard-content {
  margin-bottom: 20px;
}

.wizard-content h2 {
  margin-top: 0;
  font-size: 18px;
  color: #333;
}

.wizard-content label {
  display: block;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}



.wizard-navigation {
  display: flex;
  justify-content: flex-end;
}

button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button + button {
  margin-left: 10px;
}
