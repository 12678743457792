.left-nav-bar {
  width: 65px; min-height:100vh; height:100%;
  background: linear-gradient(to bottom, #0056b3 30%, #000 70%);
  background-size: 400% 400%;
  padding: 0 10px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index:10;
  transition: transform 0.3s ease;
  z-index:36;
  animation: gradientAnimation 3s linear infinite;
  overflow:hidden;
}
.bar{
  position:absolute; right:0; width:2px; height:100%; background:#666; z-index:2; top:0;
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 20%;
}
50% {
    background-position: 400% 40%;
}
100% {
    background-position: 0% 20%;
}
}
.left-nav-bar.hidden {
  transform: translateX(-100%);
}

.nav-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin-bottom: 1px;
  cursor: pointer;
  border: 1px solid; border-right:none;
  padding: 15px 10px;
  border-radius:7px 0px 0px 7px;
  background-color: #d1d1d1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin-right:-10px; margin-left:0px;
}

.nav-item:hover {
  background-color: var(--secondary-color);
  color: #fff; box-shadow: 0 0 5px 1px inset #000;
}
.nav-item.active{ margin-right:-21px; margin-left:0px; position:relative;
background:#F5F5F5;  box-shadow: none; border: 1px solid; border-right:none; z-index:3;
}
.nav-item.active:hover{
  background:#f9f9f9; color:#000; margin-right:-21px; box-shadow: none;     
  
  }
  .placeholder-text {
   
    transform-origin: bottom left;
    position: absolute;
    border:none;
    bottom: 60px;
    left: 0px; /* Adjust the left value as needed */
    z-index:40;
    width: 200px; /* Adjust the width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #FFF;
    transform: translateX(100px)  rotate(-90deg);
    transition: transform 0.3s ease;
  }
  
  .placeholder-text.visible {
    transform: translateX(80px) rotate(-90deg);
  
  }
  
  .placeholder-text.hidden {
    display: none;
  }
.toggle-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #999;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 9999;
}

.toggle-button:hover {
  background-color: #0056b3;
}
