:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-color: #f5f5f5;
  --text-color: #333;
  --font-family: 'Arial', sans-serif;
}

body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}


.App {
  text-align: center;
  max-width: 800px;
  margin: 10vh auto;
}
.Block{padding-top:20%; position:absolute; top:0; left:0; background:rgba(255,255,255,.9); width:100%; height:100%; z-index:25;   }
.milestonecon .milestones {
  display: inline-block;
  width: 200px;
}

.milestonecon .wizard {
  margin: 10px;
  display: inline-block;
  width: calc(100% - 250px);
  vertical-align: top;
}

.App-header {
  background-color: var(--primary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.contact-form input, select {
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

 input,
 select,
 textarea {
  width: 100%; padding:15px;
  font-size:14px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.contact-form button {
  padding: 8px 12px;
  background-color: #2196f3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
