.address-book {

  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-form {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-radius:6px; background:#e9e9e9; padding:15px; 
}


.contact-list {
  margin-top: 20px;
}

.contact {
  cursor: pointer;
  margin: 5px;
  padding: 10px;
  display:inline-block;
  width:200px; 
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  transform: perspective(500px) rotateY(20deg);
  border-radius: 4px;
  transition: transform 0.5s;
}
.contact:hover{
  
  transform: perspective(500px) rotateY(0deg);
}
.contact-name {
  font-weight: bold;
}

.contact-address {
  margin-top: 5px;
}
