.project-list-container {
  margin-bottom: 20px;
}


button{ margin:3px; }
.project-list {
  display: flex;
  flex-direction: column;
}

.project {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 3px #666;
  cursor: pointer;
  background:linear-gradient(#e9e9e9,#FFF);
  transform: perspective(500px) rotateX(20deg);
  border-radius: 4px;
  transition: transform 0.5s;
}
.project:hover {
transform: perspective(500px) rotateX(0deg); background:#999;
box-shadow: 0 0px 3px #666;
color:#FFF;
  border-radius: 4px;
  transition: transform 0.5s;
}

.project-info {
  flex-grow: 1;
}

.project button {
  margin-left: 10px;
}
