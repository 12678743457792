.container {
  display: flex;
  align-items: center;
  width: 110%;
  height:80%; 
  overflow-y:auto; 
  overflow-x:visible; 
}
.container.vertical {
  flex-direction: column;
}
.container.horizontal {
  flex-direction: row;
}
.box {
  position:relative;
  margin: 5px;
  padding: 45px 20px;
  text-align: center;
  border-radius: 2px;
  width: 150px;
  z-index:5;
  cursor:pointer;
  color: rgb(0, 0, 0);
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
background: linear-gradient(45deg, #CCC, #FFF);
}
.box .box{
width:200px;  font-size:16px; padding: 6px; 
}
.box.inline{
max-width:200px; display:inline-block; 
}
.arrow-container {
  position: relative;
  width: 100%;
  height: 15px;
  overflow: visible;
}


.arrow {
  position: absolute;
  z-index:10;
  left: calc(50% - 10px); /* Updated this line */
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid rgba(0,0,0,0.5);
  animation: fallAndPulse 1.5s infinite linear;
}



.transaction {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #000;
  text-align: center;
  margin: 0px 0;
}



.arrow-container.left,  .arrow-container.right {
height: 100%;
width: 30px;
z-index:1;
flex-direction: row;
}

.arrow-container.up .arrow {
border-top: 20px solid transparent;
border-bottom: 20px solid rgba(0,0,0,0.5);
border-left: 20px solid transparent;
border-right: 20px solid transparent;
animation: riseAndPulse 1.5s infinite linear; /* Change to riseAndPulse */
}

.arrow-container.left .arrow {
border-top: 20px solid transparent;
border-bottom: 20px solid transparent;
border-right: 20px solid rgba(0,0,0,0.5);
animation: slideAndPulseLeft 1.5s infinite linear;
}

.arrow-container.right .arrow{
 border-top: 20px solid transparent;
border-bottom: 20px solid transparent;
border-left: 20px solid rgba(255,255,255,0.5);
animation: slideAndPulseRight 1.5s infinite linear;
}

.arrow.delay {
  animation-delay: 0.75s;
}
.up .arrow.delay {
animation-delay: 0.75s;
}

.left .arrow.delay {
animation-delay: 0.75s;
}
.right .arrow.delay {
animation-delay: 0.75s;
}



@keyframes riseAndPulse {
0% { transform: translateY(20px) scale(1); opacity: 0; }
50% { transform: translateY(-15px) scale(1.3); opacity: 1; }
100% { transform: translateY(-50px) scale(1); opacity: 0; }
}
@keyframes fallAndPulse {
  0% { transform: translateY(-20px) scale(1); opacity: 0; }
  50% { transform: translateY(15px) scale(1.3); opacity: 1; }
  100% { transform: translateY(50px) scale(1); opacity: 0; }
}
@keyframes slideAndPulseRight {
0% { transform: translateX(-20px) scale(1); opacity: 0; }
50% { transform: translateX(15px) scale(1.3); opacity: 1; }
100% { transform: translateX(50px) scale(1); opacity: 0; }
}
@keyframes slideAndPulseLeft {
0% { transform: translateX(50px) scale(1); opacity: 0; }
50% { transform: translateX(15px) scale(1.3); opacity: 1; }
100% { transform: translateX(-20px) scale(1); opacity: 0; }
}

.vertical .box {
transform: perspective(500px) rotateX(50deg);
transition: transform 0.5s;
box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.horizontal .box {
transform: perspective(500px) rotateY(-20deg);
transition: transform 0.5s;
}

/* Add interaction */
.vertical .box:hover {
transform: perspective(500px) rotateX(0deg);
background: var(--primary-color); 
 box-shadow:0 0px 0px 0px #FFF;  
 z-index:15;
}

.horizontal .box:hover {
transform: perspective(500px) rotateY(0deg);
    background: linear-gradient(45deg, rgba(204, 111, 34,0.9), rgba(204, 111, 34,0.9));
}
